export const DemandScenarios = {
  baseDemand: {
    label: 'Base Demand',
    id: '322a1289-ffca-46c4-89ef-69412df81a78',
    key: 'baseDemand',
  },
  highDemand: {
    label: 'High Demand',
    id: 'cc515173-e2fd-459e-ba70-342abd576cb3',
    key: 'highDemand',
  },
  rawMaterialConstrained: {
    label: 'Raw Material Constrained',
    id: 'c8bc36b6-fb43-4959-9538-64ba6855f1e8',
    key: 'rawMaterialConstrained',
  },
}
